<template>
  <div id="app" v-title data-title="管理后台">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>
<script>
import { Message } from 'element-ui'
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload,
      baseColor: '',
      baseBg: '',
    }
  },
  data() {
    return {
      isRouterAlive: true,
    }
  },
  mounted() {},
  created() {
    this.initStyle()
    // 根据不同ClientId来显示不同的.ico文件
    this.changeFavicon()
    if (window.location.href.indexOf('/login') == -1) {
      this.initWebSocket()
    }
  },
  methods: {
    initStyle() {
      let style = {
        baseColor: '#4babf6',
        baseBg: 'linear-gradient(0deg, #3B8FFF, #4BABF6)',
      }
      // let themeHref = `./theme/element/index.css`
      if (this.IsChangeLoginPage) {
        style = {
          baseColor: '#198bab',
          baseBg: 'linear-gradient(0deg, #198bab, #198bab)',
        }
        // let themeHref = require(`./theme/elementJP/index.css`)
        // themeHref = `./theme/elementJP/index.css`
      }
      // this.loadCSS(themeHref)
      this.setStyle(style.baseColor, style.baseBg)
    },
    loadCSS(href) {
      const link = document.createElement('link')
      link.type = 'text/css'
      link.rel = 'stylesheet'
      link.href = href
      document.getElementsByTagName('head')[0].appendChild(link)
    },
    setStyle(baseColor, baseBg) {
      const cssVariables = {
        '--base--Color': baseColor,
        '--base--bg': baseBg,
      }
      // 遍历变量并设置到根元素
      Object.keys(cssVariables).forEach((key) => {
        document.documentElement.style.setProperty(key, cssVariables[key])
      })
    },
    changeFavicon() {
      let favicon = document.querySelector('link[rel="icon"]')
      let link = `./favicon.ico`
      if (this.ClientId == this.TotalClient) {
        link = `./${this.BaseStyle.totalBase.icoName}.ico`
      }
      if (this.ClientId == this.AssociationClient) {
        link = this.BaseStyle.associationBase.icoName ? `./${this.BaseStyle.associationBase.icoName}.ico` : `faviconMz.ico`
      }

      if (favicon !== null) {
        favicon.href = link
      } else {
        favicon = document.createElement('link')
        favicon.rel = 'icon'
        favicon.href = link
        document.head.appendChild(favicon)
      }
    },
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    async initWebSocket() {
      let token = JSON.parse(sessionStorage.getItem(this.ClientId + 'Token')).access_token
      this.$socket.connect(this.Services.MsgWss, token)
    },
  },
}
</script>
<style>
* {
  margin: 0;
  padding: 0;
}

::v-deep .optionBtn:last-child::after {
  content: '' !important;
  margin: 0 !important;
}
</style>
